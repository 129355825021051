import React, { useState } from "react";
import "./index.css";
import BeforeAfter from "../before-after/BeforeAfter";
import Footer from "../footer/Footer";
import Faq from "../our-specialist/faq/Faq";
import { useSelector } from "react-redux";
import {
  LeftAnimatedDiv,
  RightAnimatedDiv,
  ZoomInDiv,
} from "../../componet/Animation";
import useDivInView, {
  LEFT_VARIANTS,
  RIGHT_VARIANTS,
  TRANSITION,
} from "../../hooks/useDivInView";
import { motion } from "framer-motion";
import ShoppingFeature from "../shopping-feature/ShoppingFeature";
import Navbar from "../nav/Navbar";
import SouthIcon from "@mui/icons-material/South";
import { useNavigate } from "react-router-dom";

const ViewDiv1 = ({ item }) => {
  const [ref, control] = useDivInView();

  return (
    <motion.div
      className="sub-section-4-htw d-flex"
      ref={ref}
      animate={control}
      initial="hidden"
      variants={RIGHT_VARIANTS}
      transition={TRANSITION}
    >
      <div className="sub-img-section-4-htw">
        <img src={item?.img} style={{ height: "100%", width: "100%" }} />
      </div>
      <div
        className="d-flex flex-column horizontal-card-text"
      >
        {/* <div className="sub-text-1-section-4-htw">{e?.desc}</div> */}
        <div className="sub-text-2-section-4-htw">{item?.text}</div>
      </div>
    </motion.div>
  );
};

export default function OnlineHairTest() {
  const [read1, setRead1] = useState(false);
  const [read2, setRead2] = useState(false);
  const [read3, setRead3] = useState(false);
  const [read4, setRead4] = useState(false);
  const [read5, setRead5] = useState(false);
  const [read6, setRead6] = useState(false);
  const [read7, setRead7] = useState(false);
  const [read8, setRead8] = useState(false);
  const [read9, setRead9] = useState(false);
  const [read10, setRead10] = useState(false);

  const content = useSelector((state) => state.content.expertise);
  console.log("jojkrgreor", content);

  const navigate = useNavigate();

  let section4 = [
    {
      img: "https://res.cloudinary.com/drkpwvnun/image/upload/v1729423548/hair-assessment/gctpdtkibo3nen5zcnhm.png",
      text: "Identify the Root Cause Is your hair loss or damage due to genetics, stress, diet, or an underlying medical condition? Our online test helps pinpoint the exact cause of your hair concerns, giving you a clear path to address them.",
    },
    {
      img: "https://res.cloudinary.com/drkpwvnun/image/upload/v1729423480/hair-assessment/iyg4qyjpehesjctlcv6z.png",
      //   desc: "Hair Procedures",
      text: "Get Personalized Solutions with Expert Support After identifying the cause of your hair issues, our dermatologists and hair counselors work together to provide customized solutions. Whether it's recommending the best hair growth treatments or offering lifestyle adjustments, our experts ensure the recommendations are perfectly suited to your needs.",
    },
    {
      img: "https://res.cloudinary.com/drkpwvnun/image/upload/v1729423573/hair-assessment/z5bhoesxvlh89xjdclj5.png",
      //   desc: "Hair Transplants",
      text: "Save Time and Money No more trial and error. Our online test, combined with insights from our dermatologists and hair counselors, gives you accurate and expert-backed suggestions, saving you from spending on ineffective products.",
    },
    {
      img: "https://res.cloudinary.com/drkpwvnun/image/upload/v1729423500/hair-assessment/lfqafjizvupmqwdhg83w.png",
      //   desc: "Natural Remedies",
      text: "Comprehensive Hair Analysis from a Dermatologist As part of the process, you’ll receive a detailed hair analysis from one of our experienced dermatologists, who will review your test results and images to provide a professional diagnosis. This ensures your treatment plan is based on medical expertise.",
    },
    {
      img: "https://res.cloudinary.com/drkpwvnun/image/upload/v1729423530/hair-assessment/zl161vfmpve9bnjc3eqs.png",
      //   desc: "Lifestyle Adjustments",
      text: "Take Control of Your Hair Health with Ongoing Support Beyond the test, our hair counselors are available to guide you through your hair care journey. They’ll answer your questions, help you understand your treatment options, and provide ongoing support as you work toward healthier, stronger hair",
    },
  ];

  const [selectedQ, setSelectedQ] = useState(0);

  let section8 = [
    {
      desc: "How does the Hairscares Online Hair Test work? ",
      text: "The Hairscares Online Hair Test is a quick and easy process where you answer questions about your hair type, concerns, and lifestyle. You can also upload images for a more detailed analysis. Our dermatologists and hair counselors then review your responses and images to provide personalized treatment recommendations.",
    },
    {
      desc: "Will I get a professional diagnosis through the online test?",
      text: "Yes! After completing the test, your results and images will be reviewed by one of our experienced dermatologists, who will provide a thorough diagnosis and recommend a personalized treatment plan based on your unique hair needs.",
    },
    {
      desc: "How long does it take to receive my personalized hair analysis? ",
      text: "Once you complete the Hairscares Online Hair Test and submit your images, you’ll typically receive a detailed analysis and personalized recommendations from our dermatologists within 24 to 48 hours.",
    },
    {
      desc: "What happens after I receive my test results? ",
      text: "After receiving your personalized hair analysis, you can consult with our hair counselors to discuss the recommended treatment plan. You’ll have the option to purchase the treatments directly, and all the necessary products will be delivered right to your doorstep.",
    },
    {
      desc: "Is there a cost for the Hairscares Online Hair Test? ",
      text: "Yes, there is a nominal charge for the online hair test and consultation. This fee covers your personalized hair analysis by a dermatologist and access to expert recommendations tailored to your specific hair concerns. Any treatments you choose to purchase will then be delivered conveniently to your home.",
    },
  ];

  const [ref_11, control_11] = useDivInView();
  const [ref_12, control_12] = useDivInView();
  const [headingRef_1, headingControl_1] = useDivInView();

  const [ref_21, control_21] = useDivInView();
  const [ref_22, control_22] = useDivInView();

  const [ref_31, control_31] = useDivInView();
  const [ref_32, control_32] = useDivInView();
  const [headingRef_31, headingControl_31] = useDivInView();
  const [headingRef_32, headingControl_32] = useDivInView();
  const [headingRef_33, headingControl_33] = useDivInView();
  const [headingRef_34, headingControl_34] = useDivInView();

  const [ref_41, control_41] = useDivInView();
  const [ref_42, control_42] = useDivInView();
  const [ref_43, control_43] = useDivInView();
  const [ref_44, control_44] = useDivInView();
  const [ref_45, control_45] = useDivInView();
  const [ref_46, control_46] = useDivInView();
  const [ref_47, control_47] = useDivInView();
  const [headingRef_41, headingControl_41] = useDivInView();
  const [headingRef_42, headingControl_42] = useDivInView();
  const [headingRef_43, headingControl_43] = useDivInView();
  const [headingRef_44, headingControl_44] = useDivInView();
  const [headingRef_45, headingControl_45] = useDivInView();
  const [headingRef_46, headingControl_46] = useDivInView();
  const [headingRef_47, headingControl_47] = useDivInView();

  const [ref_51, control_51] = useDivInView();
  const [ref_52, control_52] = useDivInView();

  const [ref_61, control_61] = useDivInView();
  const [ref_62, control_62] = useDivInView();

  const [ref_71, control_71] = useDivInView();

  return (
    <Navbar>
      <div
        className="d-flex flex-column"
        style={{ fontFamily: '"Poppins", sans-serif' }}
      >
        <div
          style={{
            background: "rgba(193, 237, 255, 1)",
            padding: "40px 0 0px 0",
          }}
        >
          <div className="d-flex flex-column container">
            <div style={{ display: "flex", justifyContent: "center" }}>
              <motion.div
                className="text-2-section-4-htw width-for-text-heading-80"
                style={{ padding: "0" }}
                ref={headingRef_1}
                variants={{ hidden: { opacity: 0 }, visible: { opacity: 1 } }}
                initial="hidden"
                animate={headingControl_1}
                transition={{ duration: 1, delay: 0.5 }}
              >
                Online <span className="blue-btw-text">Hair Loss Test</span>: A
                Convenient Tool for Diagnosing{" "}
                <span className="blue-btw-text">Hair Thinning</span> and Loss
              </motion.div>
            </div>
            <div className="d-flex main-section-1-op">
              <motion.div
                className="d-flex flex-column main-section-1-op-left"
                style={{ padding: 0 }}
                ref={ref_11}
                animate={control_11}
                initial="hidden"
                variants={LEFT_VARIANTS}
                transition={TRANSITION}
              >
                <div className="text-1-section-1-htw-left">Introduction</div>
                <div className="text-3-section-1-htw-left">
                  Hair loss is a common issue that impacts millions of people
                  around the globe. Whether caused by aging, genetics, or other
                  health factors, it can take a toll on confidence and
                  self-esteem. Thankfully, modern technology allows for a
                  convenient way to assess the causes and severity of hair loss
                  through our online hair test.
                  <br />
                  <br />
                  Curious about what might be behind your hair loss? Our online
                  hair test provides quick insights into potential causes,
                  making
                  {!read1 ? (
                    <div
                      onClick={() => {
                        setRead1(!read1);
                      }}
                      style={{
                        fontWeight: "700",
                        fontSize: "20px",
                        cursor: "pointer",
                      }}
                    >
                      Read More
                    </div>
                  ) : (
                    <>
                      {" "}
                      it an excellent first step in understanding your hair
                      concerns. At HairsnCares.com, we’ve developed a
                      comprehensive assessment to help you identify the
                      underlying factors contributing to your hair loss and
                      guide you toward personalized treatment options.
                      <br />
                      <br />
                      Join us as we delve into how our online hair loss test
                      works, what it evaluates, and how you can take proactive
                      steps toward better hair health today.
                    </>
                  )}
                </div>
              </motion.div>
              <motion.div
                className="d-flex flex-column main-section-1-op-right"
                ref={ref_12}
                animate={control_12}
                initial="hidden"
                variants={RIGHT_VARIANTS}
                transition={TRANSITION}
              >
                <img src="https://res.cloudinary.com/drkpwvnun/image/upload/v1729419544/hair-assessment/vxtmkoan3yafrsuaabbw.png" />
              </motion.div>
            </div>
          </div>
        </div>

        <div
          className="d-flex flex-column main-section-2-htw container"
        >
          <div className="desktop-view" style={{ gap: "30px" }}>
            <motion.div
              className="main-section-2-htw-left"
              style={{ height: "100%" }}
              ref={ref_22}
              animate={control_22}
              initial="hidden"
              variants={LEFT_VARIANTS}
              transition={TRANSITION}
            >
              <img
                src="https://res.cloudinary.com/drkpwvnun/image/upload/v1729419691/hair-assessment/nicr0fgyvqwoc1jtifim.png"
                style={{ height: "100%", width: "100%" }}
              />
            </motion.div>
            <motion.div
              className=" main-section-2-htw-right d-flex flex-column"
              ref={ref_21}
              animate={control_21}
              initial="hidden"
              variants={RIGHT_VARIANTS}
              transition={TRANSITION}
            >
              <div
                className="text-2-section-2-htw"
                style={{ textAlign: "left" }}
              >
                What Is <span className="blue-btw-text">HairsnCares</span>{" "}
                Online Hair Loss Test?
              </div>
              <div className="text-3-section-2-htw m-top">
                HairsnCares Online Hair Loss Test is a user-friendly digital
                tool designed to help you identify the potential causes of hair
                thinning or loss. The process begins with a comprehensive
                questionnaire that covers your lifestyle, diet, stress levels,
                and specific hair and scalp-related concerns. Following this,
                you can upload images of your scalp or hair for a more
                personalized analysis
                <br />
                <br />
                In the next step, a qualified dermatologist evaluates your
                complete hair test and images, generating a detailed analysis
                and management report. This can be provided with or without a
                virtual consultation. Once the analysis is complete, the
                dermatologist will create a tailored prescription, and the
                recommended medications will be delivered directly to your
                doorstep.
              </div>
              <div className="">
                <button
                  onClick={() => navigate("/our-expertise#read3")}
                  className="btn sub-button-section-3-op"
                >
                  Start Your Hair Assessment{" "}
                </button>
              </div>
            </motion.div>
          </div>
        </div>

        <div
          className="d-flex flex-column main-section-3-oht"
          style={{ padding: "4% 0 4% 0" }}
        >
          <div className="desktop-view-1 container" style={{ gap: "30px" }}>
            <div className=" main-section-2-htw-right d-flex flex-column">
              <motion.div
                className="text-2-section-2-htw"
                style={{ textAlign: "left", color: "white" }}
                ref={ref_32}
                animate={control_32}
                initial="hidden"
                variants={LEFT_VARIANTS}
                transition={TRANSITION}
              >
                Key Features of Our{" "}
                <span className="blue-btw-text">Online Hair Loss</span> Test{" "}
              </motion.div>

              <div className="d-flex flex-column  mt-5 gap-desktop" style={{ gap: "20px" }}>
                <motion.div
                  className="d-flex gap-desktop"
                  // style={{ gap: "20px" }}
                  ref={headingRef_31}
                  variants={{ hidden: { opacity: 0 }, visible: { opacity: 1 } }}
                  initial="hidden"
                  animate={headingControl_31}
                  transition={{ duration: 1, delay: 0.5 }}
                >
                  <div
                    className="mini-card-num-div-section-4-htw number-view"
                  >
                    <div className="mini-card-num-text-section-4-htw flex-center-row">
                      1
                    </div>
                  </div>
                  <div
                    className="text-3-section-2-htw"
                    style={{ color: "white", width: "80%" }}
                  >
                    <strong>Questionnaire-Based</strong>
                    <br />
                    The test collects information on factors such as diet,
                    stress levels, medical conditions, and family history of
                    hair loss
                  </div>
                </motion.div>

                <motion.div
                  className="d-flex gap-desktop"
                  // style={{ gap: "20px" }}
                  ref={headingRef_32}
                  variants={{ hidden: { opacity: 0 }, visible: { opacity: 1 } }}
                  initial="hidden"
                  animate={headingControl_32}
                  transition={{ duration: 1, delay: 0.5 }}
                >
                  <div
                    className="mini-card-num-div-section-4-htw number-view"
                  >
                    <div className="mini-card-num-text-section-4-htw flex-center-row">
                      2
                    </div>
                  </div>
                  <div
                    className="text-3-section-2-htw"
                    style={{ color: "white", width: "80%" }}
                  >
                    <strong>Image Analysis</strong>
                    <br />
                    You have the option to upload photos of your scalp for a
                    thorough evaluation, enabling the identification of specific
                    issues
                  </div>
                </motion.div>

                <motion.div
                  className="d-flex gap-desktop"
                  // style={{ gap: "20px" }}
                  ref={headingRef_33}
                  variants={{ hidden: { opacity: 0 }, visible: { opacity: 1 } }}
                  initial="hidden"
                  animate={headingControl_33}
                  transition={{ duration: 1, delay: 0.5 }}
                >
                  <div
                    className="mini-card-num-div-section-4-htw number-view"
                    // style={{ padding: "10px", width: "8%", height: "7.5vh" }}
                  >
                    <div className="mini-card-num-text-section-4-htw flex-center-row">
                      3
                    </div>
                  </div>
                  <div
                    className="text-3-section-2-htw"
                    style={{ color: "white", width: "80%" }}
                  >
                    <strong>Personalized Recommendations</strong>
                    <br />
                    Based on your responses, the test delivers insights into
                    potential causes of your hair loss and suggests appropriate
                    treatment options or professional consultations.
                  </div>
                </motion.div>
              </div>

              <motion.div
                className="text-3-section-2-htw mt-5"
                style={{ color: "white" }}
                ref={headingRef_34}
                variants={{ hidden: { opacity: 0 }, visible: { opacity: 1 } }}
                initial="hidden"
                animate={headingControl_34}
                transition={{ duration: 1, delay: 0.5 }}
              >
                By examining various factors—including family history, hormonal
                imbalances, nutritional deficiencies, scalp conditions, stress
                levels, and lifestyle habits—this test provides valuable
                insights. Simply complete the questionnaire, and you’ll receive
                guidance on the factors affecting your hair health, along with
                recommendations for next steps.
              </motion.div>
            </div>
            <motion.div
              className="main-section-2-htw-left"
              ref={ref_31}
              animate={control_31}
              initial="hidden"
              variants={RIGHT_VARIANTS}
              transition={TRANSITION}
            >
              <img
                src="https://res.cloudinary.com/drkpwvnun/image/upload/v1729420618/hair-assessment/pnjiplhmgyq25atpby6d.png"
                style={{ height: "100%", width: "100%" }}
              />
            </motion.div>
          </div>
        </div>

        <div
          className="d-flex flex-column main-section-2-htw container"
          style={{ padding: "4% 0 4% 0" }}
        >
          <motion.div
            className="text-2-section-2-htw"
            ref={ref_41}
            animate={control_41}
            initial="hidden"
            variants={RIGHT_VARIANTS}
            transition={TRANSITION}
          >
            How Does the <span className="blue-btw-text">Hair Loss Test</span>{" "}
            Work at HairsnCares.com?
          </motion.div>
          <div className="d-flex flex-column" style={{ gap: "0" }}>
            <motion.div
              className="sub-main-section-4-oht d-flex flex-column"
              style={{ alignItems: "center" }}
              ref={headingRef_41}
              variants={{ hidden: { opacity: 0 }, visible: { opacity: 1 } }}
              initial="hidden"
              animate={headingControl_41}
              transition={{ duration: 1, delay: 0.5 }}
            >
              <div style={{ width: "58px" }}>
                <img
                  src={
                    "https://res.cloudinary.com/drkpwvnun/image/upload/v1729421699/hair-assessment/epjovva0dmly3v2lmfex.png"
                  }
                  style={{ width: "100%", height: "100%" }}
                />
              </div>

              <div
                className="main-heading-text-section-8-htw"
                style={{ fontWeight: "600" }}
              >
                Take an online Test
              </div>
              <div className="">
                <button
                  onClick={() => navigate("/our-expertise#read3")}
                  className="btn sub-button-section-3-op"
                >
                  Start Test
                </button>
              </div>
            </motion.div>

            <motion.div
              style={{ padding: "20px" }}
              className="flex-center-row"
              ref={ref_42}
              animate={control_42}
              initial="hidden"
              variants={RIGHT_VARIANTS}
              transition={TRANSITION}
            >
              <SouthIcon style={{ fontSize: "30px" }} />
            </motion.div>

            <motion.div
              className="sub-main-section-4-oht d-flex flex-column"
              style={{ alignItems: "center" }}
              ref={headingRef_42}
              variants={{ hidden: { opacity: 0 }, visible: { opacity: 1 } }}
              initial="hidden"
              animate={headingControl_42}
              transition={{ duration: 1, delay: 0.5 }}
            >
              <div style={{ width: "40px" }}>
                <img
                  src={
                    "https://res.cloudinary.com/drkpwvnun/image/upload/v1729422713/hair-assessment/xz52yyjzubnxi3gezzjy.png"
                  }
                  style={{ width: "100%", height: "100%" }}
                />
              </div>

              <div
                className="main-heading-text-section-8-htw"
                style={{ fontWeight: "600" }}
              >
                Drag & Drop or choose file to upload{" "}
              </div>
              <div className="">
                <button
                  onClick={() => navigate("/our-expertise#read3")}
                  className="btn sub-button-section-3-op"
                >
                  Upload Now{" "}
                </button>
              </div>
            </motion.div>

            <motion.div
              style={{ padding: "20px" }}
              className="flex-center-row"
              ref={ref_43}
              animate={control_43}
              initial="hidden"
              variants={RIGHT_VARIANTS}
              transition={TRANSITION}
            >
              <SouthIcon style={{ fontSize: "30px" }} />
            </motion.div>

            <motion.div
              className="sub-main-section-4-oht d-flex flex-column"
              style={{ alignItems: "center" }}
              ref={headingRef_43}
              variants={{ hidden: { opacity: 0 }, visible: { opacity: 1 } }}
              initial="hidden"
              animate={headingControl_43}
              transition={{ duration: 1, delay: 0.5 }}
            >
              <div style={{ width: "58px" }}>
                <img
                  src={
                    "https://res.cloudinary.com/drkpwvnun/image/upload/v1729422907/hair-assessment/axhoz2fya2iwgbxb6bqa.png"
                  }
                  style={{ width: "100%", height: "100%" }}
                />
              </div>

              <div
                className="main-heading-text-section-8-htw"
                style={{ fontWeight: "600" }}
              >
                online consultation{" "}
              </div>
              <div className="">
                <button
                  onClick={() => navigate("/our-expertise#read3")}
                  className="btn sub-button-section-3-op"
                >
                  Book Consultation{" "}
                </button>
              </div>
            </motion.div>

            <motion.div
              style={{ padding: "20px" }}
              className="flex-center-row"
              ref={ref_44}
              animate={control_44}
              initial="hidden"
              variants={RIGHT_VARIANTS}
              transition={TRANSITION}
            >
              <SouthIcon style={{ fontSize: "30px" }} />
            </motion.div>

            <motion.div
              className="sub-main-section-4-oht d-flex flex-column"
              style={{ alignItems: "center" }}
              ref={headingRef_44}
              variants={{ hidden: { opacity: 0 }, visible: { opacity: 1 } }}
              initial="hidden"
              animate={headingControl_44}
              transition={{ duration: 1, delay: 0.5 }}
            >
              <div style={{ width: "58px" }}>
                <img
                  src={
                    "https://res.cloudinary.com/drkpwvnun/image/upload/v1729422943/hair-assessment/qbftkmjkkbyuc4tqncse.png"
                  }
                  style={{ width: "100%", height: "100%" }}
                />
              </div>

              <div
                className="main-heading-text-section-8-htw"
                style={{ fontWeight: "600" }}
              >
                Get Detailed Analysis{" "}
              </div>
              <div className="">
                <button
                  onClick={() => navigate("/our-expertise#read3")}
                  className="btn sub-button-section-3-op"
                >
                  View Analysis{" "}
                </button>
              </div>
            </motion.div>

            <motion.div
              style={{ padding: "20px" }}
              className="flex-center-row"
              ref={ref_45}
              animate={control_45}
              initial="hidden"
              variants={RIGHT_VARIANTS}
              transition={TRANSITION}
            >
              <SouthIcon style={{ fontSize: "30px" }} />
            </motion.div>

            <motion.div
              className="sub-main-section-4-oht d-flex flex-column"
              style={{ alignItems: "center" }}
              ref={headingRef_45}
              variants={{ hidden: { opacity: 0 }, visible: { opacity: 1 } }}
              initial="hidden"
              animate={headingControl_45}
              transition={{ duration: 1, delay: 0.5 }}
            >
              <div style={{ width: "58px" }}>
                <img
                  src={
                    "https://res.cloudinary.com/drkpwvnun/image/upload/v1729423075/hair-assessment/qnvwakivy8g6jk844fap.png"
                  }
                  style={{ width: "100%", height: "100%" }}
                />
              </div>

              <div
                className="main-heading-text-section-8-htw"
                style={{ fontWeight: "600" }}
              >
                Receive Your Report and Prescription{" "}
              </div>
              <div className="">
                <button
                  onClick={() => navigate("/our-expertise#read3")}
                  className="btn sub-button-section-3-op"
                >
                  Download Report{" "}
                </button>
              </div>
            </motion.div>

            <motion.div
              style={{ padding: "20px" }}
              className="flex-center-row"
              ref={ref_46}
              animate={control_46}
              initial="hidden"
              variants={RIGHT_VARIANTS}
              transition={TRANSITION}
            >
              <SouthIcon style={{ fontSize: "30px" }} />
            </motion.div>

            <motion.div
              className="sub-main-section-4-oht d-flex flex-column"
              style={{ alignItems: "center" }}
              ref={headingRef_46}
              variants={{ hidden: { opacity: 0 }, visible: { opacity: 1 } }}
              initial="hidden"
              animate={headingControl_46}
              transition={{ duration: 1, delay: 0.5 }}
            >
              <div style={{ width: "58px" }}>
                <img
                  src={
                    "https://res.cloudinary.com/drkpwvnun/image/upload/v1729423037/hair-assessment/kijdcp1y7rt1fzan3buo.png"
                  }
                  style={{ width: "100%", height: "100%" }}
                />
              </div>

              <div
                className="main-heading-text-section-8-htw"
                style={{ fontWeight: "600" }}
              >
                Buy Recommended Treatment Plan{" "}
              </div>
              <div className="">
                <button
                  onClick={() => navigate("/our-expertise#read3")}
                  className="btn sub-button-section-3-op"
                >
                  View Treatment Plan{" "}
                </button>
              </div>
            </motion.div>

            <motion.div
              style={{ padding: "20px" }}
              className="flex-center-row"
              ref={ref_47}
              animate={control_47}
              initial="hidden"
              variants={RIGHT_VARIANTS}
              transition={TRANSITION}
            >
              <SouthIcon style={{ fontSize: "30px" }} />
            </motion.div>

            <motion.div
              className="sub-main-section-4-oht d-flex flex-column"
              style={{ alignItems: "center" }}
              ref={headingRef_47}
              variants={{ hidden: { opacity: 0 }, visible: { opacity: 1 } }}
              initial="hidden"
              animate={headingControl_47}
              transition={{ duration: 1, delay: 0.5 }}
            >
              <div style={{ width: "58px" }}>
                <img
                  src={
                    "https://res.cloudinary.com/drkpwvnun/image/upload/v1729423119/hair-assessment/ef1svqy5shonmpc7cktc.png"
                  }
                  style={{ width: "100%", height: "100%" }}
                />
              </div>

              <div
                className="main-heading-text-section-8-htw"
                style={{ fontWeight: "600" }}
              >
                Medicine Delivered{" "}
              </div>
              <div className="">
                <button
                  onClick={() => navigate("/our-expertise#read3")}
                  className="btn sub-button-section-3-op"
                >
                  Order Medicine{" "}
                </button>
              </div>
            </motion.div>
          </div>
        </div>

        <div style={{ background: "rgba(193, 237, 255, 1)" }}>
          <div
            className="d-flex flex-column container"
            style={{ padding: "3% 0 4% 0" }}
          >
            <motion.div
              style={{ display: "flex", justifyContent: "center" }}
              ref={ref_51}
              animate={control_51}
              variants={{ hidden: { opacity: 0 }, visible: { opacity: 1 } }}
              initial="hidden"
              transition={{ duration: 1, delay: 0.5 }}
            >
              <div className="text-2-section-4-htw width-for-text-heading-80">
                Why Should You Take the{" "}
                <span className="blue-btw-text">Hairscares</span> Online Hair
                Test?
              </div>
            </motion.div>
            <motion.div
              style={{ display: "flex", justifyContent: "center" }}
              ref={ref_52}
              animate={control_52}
              variants={{ hidden: { opacity: 0 }, visible: { opacity: 1 } }}
              initial="hidden"
              transition={{ duration: 1, delay: 0.5 }}
            >
              <div className="text-3-section-4-htw" style={{ width: "75%" }}>
                Understanding the root cause of your hair concerns is essential
                for choosing the most effective treatment. Without identifying
                the underlying issue, you may end up wasting time and money on
                solutions that don't work. Here’s why taking the Hairscares
                Online Hair Test can benefit you
              </div>
            </motion.div>

            <div className="d-flex flex-column mt-3" style={{ gap: "15px" }}>
              {section4.map((e, indx) => {
                return <ViewDiv1 item={e} />;
              })}
            </div>
          </div>
        </div>

        <div>
          <div className="main-section-1-htw desktop-view-1 container">
            <motion.div
              className="d-flex flex-column main-section-1-htw-left"
              style={{ padding: 0 }}
              ref={ref_61}
              animate={control_61}
              initial="hidden"
              variants={LEFT_VARIANTS}
              transition={TRANSITION}
            >
              <div className="text-1-section-1-htw-left">Conclusion</div>
              <div className="text-3-section-1-htw-left">
                Taking the Hairscares Online Hair Test is a simple yet powerful
                step toward understanding and improving your hair health. By
                identifying the root cause of your hair concerns and receiving
                expert advice from our dermatologists and hair counselors,
                you’ll gain access to personalized, effective solutions—all at
                your fingertips. With the guidance of our medical professionals,
                you can avoid wasting time and money on ineffective treatments
                and take control of your hair health with confidence.
                <br />
                <br />
                Ready to take the first step? Take the Hairscares Online Hair
                Test today and unlock your personalized treatment plan, with
                expert-recommended treatments delivered right to your doorstep.
                Start your journey to healthier, stronger hair now!
              </div>
              <div className="">
                <button
                  onClick={() => navigate("/hair-test")}
                  className="btn sub-button-section-3-op"
                >
                  Take the Hair Test Now
                </button>
              </div>
            </motion.div>
            <motion.div
              className="d-flex flex-column main-section-1-htw-right"
              ref={ref_62}
              animate={control_62}
              initial="hidden"
              variants={RIGHT_VARIANTS}
              transition={TRANSITION}
            >
              <img src="https://res.cloudinary.com/drkpwvnun/image/upload/v1729423835/hair-assessment/n2d3ljp46nkf9srfqviu.png" />
            </motion.div>
          </div>
        </div>

        <div
          style={{
            background: "rgba(193, 237, 255, 1)",
            padding: "40px 0 40px 0",
          }}
        >
          <div className="container mt-3">
            <motion.div
              className="main-text-section-8-htw"
              ref={ref_71}
              animate={control_71}
              variants={{ hidden: { opacity: 0 }, visible: { opacity: 1 } }}
              initial="hidden"
              transition={{ duration: 1, delay: 0.5 }}
            >
              Common Questions{" "}
              <span className="blue-btw-text">About Hair Loss</span> and Online
              Testing
            </motion.div>
            <div className="mt-5 d-flex flex-column">
              {section8?.map((item, indx) => {
                return (
                  <div className="d-flex flex-column">
                    <div
                      className="main-heading-box-section-8-htw d-flex"
                      style={{
                        justifyContent: "space-between",
                        background: "rgba(211, 239, 252, 1)",
                      }}
                    >
                      <div
                        className="main-heading-text-section-8-htw faq-text"
                        style={{ padding: "10px" }}
                      >
                        {item?.desc}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        onClick={() => {
                          if(selectedQ == indx + 1) setSelectedQ(null)
                          else setSelectedQ(indx + 1);
                        }}
                        className="faq-icon"
                      >
                        <img
                          src={"/assets/img/hairTreatmentWomen/image-16.png"}
                          className="faq-dropDown"
                        />
                      </div>
                    </div>
                    {selectedQ == indx + 1 ? (
                      <div className="main-sub-heading-text-section-8-htw">
                        {item?.text}
                      </div>
                    ) : null}
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <ShoppingFeature col={"1"} />
        <Footer />
      </div>
    </Navbar>
  );
}
