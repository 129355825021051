import React, { useState } from "react";
import "./index.css";
import BeforeAfter from "../before-after/BeforeAfter";
import Footer from "../footer/Footer";
import Faq from "../our-specialist/faq/Faq";
import { useSelector } from "react-redux";
import {
  LeftAnimatedDiv,
  RightAnimatedDiv,
  ZoomInDiv,
  ZoomInDiv2,
} from "../../componet/Animation";
import ShoppingFeature from "../shopping-feature/ShoppingFeature";
import Navbar from "../nav/Navbar";
import { Padding } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
export default function HairTransplant() {
  const [read1, setRead1] = useState(false);
  const [read2, setRead2] = useState(false);
  const [read3, setRead3] = useState(false);
  const [read4, setRead4] = useState(false);
  const [read5, setRead5] = useState(false);
  const [read6, setRead6] = useState(false);
  const [read7, setRead7] = useState(false);
  const [read8, setRead8] = useState(false);
  const [read9, setRead9] = useState(false);
  const [read10, setRead10] = useState(false);

  const content = useSelector((state) => state.content.expertise);
  console.log("jojkrgreor", content);
  const navigate = useNavigate();

  let section3 = [
    {
      img: "https://res.cloudinary.com/drkpwvnun/image/upload/v1729540267/hair-assessment/jogssyy5utux2pg4yl4a.png",
      desc: "FUE Hair Transplant",
      text: "Short for Follicular Unit Extraction, this method involves extracting individual hair follicles from the donor area (usually the back of your head) and placing them in thinning or bald areas. It’s minimally invasive, leaves no visible scars, and provides a natural look. I’ve used this technique on hundreds of patients with great success. Learn more about FUE",
    },
    {
      img: "https://res.cloudinary.com/drkpwvnun/image/upload/v1729540289/hair-assessment/sg5lospm0wlwkkezryaq.png",
      desc: "MHI / MHI+ Hair Transplant",
      text: "Now, this is where my passion lies. I’ve refined the Modified Hair Implantation (MHI) technique to offer precise control over the depth, angle, and direction of each hair follicle, resulting in an almost bloodless process. This is perfect for achieving high-density hairlines. With MHI+, we take it up a notch by adding enhanced growth factors like PRP (Platelet-Rich Plasma) and VThreads to accelerate healing and produce thicker, healthier hair. Explore MHI/MHI+.",
    },
    {
      img: "https://res.cloudinary.com/drkpwvnun/image/upload/v1729540336/hair-assessment/shsvrvcstmpgh3obop7f.png",
      desc: "DHI Hair Transplant",
      text: "Direct Hair Implantation takes FUE one step further. Using a special tool, the hair follicles are implanted directly into the scalp without creating incisions beforehand. This means greater precision, better control over the growth angle, and faster recovery. Discover DHI Hair Transplant.",
    },
  ];

  let section4 = [
    {
      img: "https://res.cloudinary.com/drkpwvnun/image/upload/v1729540831/hair-assessment/buhlqedi2ldneiawv9ne.png",
      desc: "Immediate Aftercare",
      text: "Avoid sun exposure, don’t scratch the grafts, and follow any prescribed treatments. Simple, right?",
    },
    {
      img: "https://res.cloudinary.com/drkpwvnun/image/upload/v1729540847/hair-assessment/okps3prhpa6ltstwpjd8.png",
      desc: "Recovery Timeline",
      text: "You’ll be back to your usual routine in a few days, but avoid intense workouts for a couple of weeks. You’ll see initial results within 3-4 months, with full results in 6-10 months.",
    },
    {
      img: "https://res.cloudinary.com/drkpwvnun/image/upload/v1729540886/hair-assessment/dig50kugxr8xifyalw6u.png",
      desc: "When to Expect Results",
      text: "Hair regrowth starts in a few months, with MHI+ often showing results even faster due to enhanced growth factors.",
    },
  ];

  const [selectSec,setSelectSec] = useState(0)
  let section5 = [
    {
      desc: "Clinic Proximity",
      text: "Don’t let distance hold you back—many people travel far and wide to receive treatment from a highly reputed and experienced surgeon. Quality results are worth the journey!",
    },
    {
      desc: "Surgeon Expertise",
      text: "Make sure your surgeon has a strong track record. I always tell my patients: don’t just look for years of experience—look for positive reviews and before-and-after photos.",
    },
    {
      desc: "Consultations and Reviews ",
      text: "Take your time. Book multiple consultations, ask questions, and trust your instincts.",
    },
  ];
  const [selectedQ, setSelectedQ] = useState(0);

  let section8 = [
    {
      desc: "What is the difference between FUE, DHI, and MHI hair transplants?",
      text: "FUE (Follicular Unit Extraction) involves extracting and implanting hair follicles in two separate steps. DHI (Direct Hair Implantation) uses a special tool to extract and implant follicles in one continuous process, offering greater precision for hairline and density adjustments. MHI (Modified Hair Implantation) adds advanced implantation tools and techniques, with MHI+ incorporating enhanced growth factors and VThread to stimulate faster regrowth and thicker hair.",
    },
    {
      desc: "How much does a hair transplant cost?",
      text: "Costs depend on procedure type, grafts needed, and clinic location. MHI+ is usually priced higher due to enhanced techniques and added benefits",
    },
    {
      desc: "Are the results of a hair transplant long-lasting?",
      text: "Yes, once the follicles are established, they continue to grow naturally for life",
    },
    {
      desc: "Is a hair transplant painful?",
      text: "Not at all! With local anesthesia, most patients feel little to no pain during the procedure.",
    },
    {
      desc: "What is the expected recovery time after a hair transplant?",
      text: "You’ll be back to your daily routine in just a few days. Full regrowth takes about 6-10 months, with MHI+ offering potentially faster recovery.",
    },
  ];

  return (
    <Navbar>
      <div
        className="d-flex flex-column"
        style={{ fontFamily: '"Poppins", sans-serif' }}
      >
        <div style={{ background: "rgba(193, 237, 255, 1)" }} className="main-div-resp">
          <div className="main-section-1-htw desktop-view-1 container">
            <ZoomInDiv
              variants={{ hidden: { opacity: 0 }, visible: { opacity: 1 } }}
              transition={{ duration: 1, delay: 0.5 }}
              className="d-flex flex-column main-section-1-htw-left"
              style={{ padding: 0 }}
            >
              <div className="text-2-section-1-htw-left">
                Hair Transplants The{" "}
                <span className="blue-btw-text">Modern Solution</span> to
                Reclaiming Your <span className="blue-btw-text">Natural </span>
                Look
              </div>
              <div className="text-3-section-1-htw-left">
                As a dermatologist and hair transplant surgeon, I’ve seen
                firsthand how hair loss can affect both men and women. It’s not
                just about aesthetics—it’s about confidence and feeling like
                yourself again. That’s why hair transplants have become such a
                powerful tool. In today’s world, advanced techniques like FUE,
                DHI, MHI, and MHI+ are making it possible to achieve natural,
                undetectable results with minimal downtime. So, what exactly do
                these terms mean, and how do you know which one is best for you?
                Let’s explore.
              </div>
              <div className="">
                <button
                  // onClick={() => navigate("/hair-test")}
                  className="btn sub-button-section-3-op"
                >
                  Learn More
                </button>
              </div>
            </ZoomInDiv>
            <RightAnimatedDiv className="d-flex flex-column main-section-1-htw-right">
              <img src="https://res.cloudinary.com/drkpwvnun/image/upload/v1729540149/hair-assessment/c8jodijiebn8smcap2nn.png" />
            </RightAnimatedDiv>
          </div>
        </div>

        <div className="d-flex flex-column main-section-2-htw container main-div-resp">
          <div className="text-1-section-2-htw">The Problem</div>
          <ZoomInDiv2 className="text-2-section-2-htw">
            Hair <span className="blue-btw-text">Loss Affects Your</span>{" "}
            Confidence and Appearance
          </ZoomInDiv2>
          <div className="desktop-view" style={{gap : "5%"}}>
            <LeftAnimatedDiv className="main-section-2-htw-left">
              <img
                src="https://res.cloudinary.com/drkpwvnun/image/upload/v1729540186/hair-assessment/od16zf6bmtuwx6dd77rv.png"
                style={{ height: "90%", width: "98%" }}
              />
            </LeftAnimatedDiv>
            <RightAnimatedDiv className="text-3-section-2-htw main-section-2-htw-right d-flex flex-column" style={{gap : "10px"}}>
              <div>
                Losing your hair isn’t just a physical change—it can really mess
                with your confidence and affect how you interact with others.
                I’ve had many patients come to me frustrated by temporary fixes
                like wigs or over-the-counter treatments that don’t deliver
                lasting results. Whether it’s a receding hairline, thinning
                hair, or those dreaded bald patches, it’s time to consider a
                permanent solution.
              </div>
              <strong>
                Some common issues I hear in consultations include:
              </strong>

              <ZoomInDiv className="d-flex" style={{ gap: "20px" }}>
                <div
                  className="mini-card-num-div-section-4-htw number-view"
                  // style={{ padding: "10px"}}
                >
                  <div className="mini-card-num-text-section-4-htw flex-center-row">
                    1
                  </div>
                </div>

                <div
                  className="text-3-section-2-htw"
                  style={{ color: "black", width: "80%" }}
                >
                  I’m frustrated with spending money on products that just don’t
                  work
                </div>
              </ZoomInDiv>

              <ZoomInDiv className="d-flex" style={{ gap: "20px" }}>
                <div
                  className="mini-card-num-div-section-4-htw number-view"
                  // style={{ padding: "10px"}}
                >
                  <div className="mini-card-num-text-section-4-htw flex-center-row">
                    2
                  </div>
                </div>
                <div
                  className="text-3-section-2-htw"
                  style={{ color: "black", width: "80%" }}
                >
                  My hair loss makes me look older than I really am.
                </div>
              </ZoomInDiv>

              <ZoomInDiv className="d-flex" style={{ gap: "20px" }}>
                <div
                  className="mini-card-num-div-section-4-htw number-view"
                  // style={{ padding: "10px"}}
                >
                  <div className="mini-card-num-text-section-4-htw flex-center-row">
                    3
                  </div>
                </div>
                {/* </div> */}
                <div
                  className="text-3-section-2-htw"
                  style={{ color: "black", width: "80%" }}
                >
                  I’m self-conscious at work and in social situations because of
                  my hair.
                </div>
              </ZoomInDiv>
              <div>Sound familiar? Don’t worry, you’re not alone.</div>
              <div className="">
                <button
                  onClick={() => navigate("/hair-test")}
                  className="btn sub-button-section-3-op"
                >
                  Take a Hair Test
                </button>
              </div>
            </RightAnimatedDiv>
          </div>
        </div>

        <div className="main-section-3-htw main-div-resp">
          <div
            className="d-flex flex-column container"
            style={{ padding: "3% 0 3% 0" }}
          >
            <div className="text-1-section-3-htw">The Solution </div>
            <ZoomInDiv2 style={{ display: "flex", justifyContent: "center" }}>
              <div className="text-2-section-3-htw width-for-text-heading-80" >
                Explore <span className="blue-btw-text">FUE, DHI, MHI, and MHI+</span> for Permanent Hair Restoration
              </div>
            </ZoomInDiv2>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div className="text-3-section-3-htw width-for-text-heading-75" >
              Let’s talk about the culprits behind hair loss in women. It’s crucial to figure out the reason behind your thinning hair because the treatment will depend on the root cause. Some of the main triggers I see in my practice are:
              </div>
            </div>

            <div className="desktop-view-1 mt-3" style={{ gap: "3%" }}>
              {section3.map((e) => {
                return (
                  <ZoomInDiv2
                    className="sub-section-3-htw d-flex flex-column width-for-text-heading-100"
                    style={{height: "100%" }}
                  >
                    <div>
                      <img
                        src={e?.img}
                        style={{ height: "100%", width: "100%" }}
                      />
                    </div>
                    <div className="sub-text-1-section-3-htw">{e?.desc}</div>
                    <div className="sub-text-2-section-3-htw">{e?.text}</div>
                    {/* <div
                      style={{
                        width: "65%",
                        padding: "20px 0 10px 0",
                        gap: "10px",
                      }}
                      className="d-flex flex-column"
                    >

                    </div> */}
                  </ZoomInDiv2>
                );
              })}
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <div
                className="sub-text-2-section-3-htw mt-5 width-for-text-heading-80"
                // style={{ width: "80%" }}
              >
               Each method has its own strengths, depending on your hair type, degree of hair loss, and goals. During your consultation, we’ll figure out which option will give you the best, most natural-looking results.
              </div>
            </div>

            <RightAnimatedDiv className="flex-center-row">
              <button
                onClick={() => navigate("/hair-test")}
                className="btn sub-button-section-3-op"
              >
                Explore Hair Restoration
              </button>
            </RightAnimatedDiv>
          </div>
        </div>

        <div className="main-div-resp">
          <div
            className="d-flex flex-column container"
            style={{ padding: "3% 0 3% 0" }}
          >
            <LeftAnimatedDiv
              style={{ display: "flex", justifyContent: "center" }}
            >
              <div className="text-2-section-4-htw" >
              Understanding <span className="blue-btw-text">Hair Transplant</span> Costs
              </div>
            </LeftAnimatedDiv>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div className="text-3-section-4-htw" style={{ width: "75%" }}>
              Cost is always a big question. Trust me, I get it—hair transplants are an investment. The price varies based on the technique, the number of grafts, and the clinic’s expertise. Here’s a quick breakdown

              </div>
            </div>

            <div className="desktop-view-1 mt-3" style={{gap : "10%"}}>
            <ZoomInDiv
                    className="d-flex flex-column width-for-text-heading-50"
                    style={{
                      padding: "30px",
                      // width: "30%",
                      gap: "15px",
                    }}
                  >
                    <div
                      className="mini-card-num-div-section-4-htw number-view-2"
                    >
                      <div className="mini-card-num-text-section-4-htw flex-center-row">
                        1
                      </div>
                    </div>
                    <div
                      className="mini-card-text-1-section-4-htw"
                      style={{textAlign : "left"}}

                    >
                      FUE Hair Transplant Cost
                    </div>
                    <div
                      className="mini-card-text-2-section-4-htw"
                      style={{textAlign : "left"}}

                    >
                      Generally more affordable and effective for many, with natural results.

                    </div>
                  </ZoomInDiv>

                  <ZoomInDiv
                    className="d-flex flex-column width-for-text-heading-50"
                    style={{
                      padding: "30px",
                      // width: "30%",
                      gap: "15px",
                    }}
                  >
                    <div
                      className="mini-card-num-div-section-4-htw number-view-2"
                    >
                      <div className="mini-card-num-text-section-4-htw flex-center-row">
                        2
                      </div>
                    </div>
                    <div
                      className="mini-card-text-1-section-4-htw"
                      style={{textAlign : "left"}}
                    >
                      DHI, MHI & MHI+ Hair Transplant Cost
                    </div>
                    <div
                      className="mini-card-text-2-section-4-htw"
                      style={{textAlign : "left"}}

                    >
                      Slightly higher due to the advanced tools and increased precision, but worth it for those seeking perfect hairline density. MHI+ includes growth factors for faster regrowth, making it ideal for top-notch results.

                    </div>
                  </ZoomInDiv>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <div
                className="sub-text-2-section-3-htw mt-5"
                style={{ width: "80%", color: "black" }}
              >
Want to know more? I’d recommend exploring affordable hair transplant options and payment plans to help spread out the cost. Your confidence is worth it!

              </div>
            </div>
          </div>
        </div>

        <div className="main-section-5-htw main-div-resp">
          <div
            className="d-flex flex-column container"
            style={{ padding: "3% 0 3% 0" }}
          >
            <div style={{ display: "flex", justifyContent: "center" }}>
              <RightAnimatedDiv
                className="text-2-section-5-htw width-for-text-heading-80"
                // style={{ width: "80%" }}
              >
            How to <span className="blue-btw-text">Choose the Best Hair</span> Transplant Clinic Near You

              </RightAnimatedDiv>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div className="text-3-section-5-htw width-for-text-heading-75">
              Choosing the right hair transplant clinic can make or break your results. My advice? Look for clinics that specialize in FUE, DHI, MHI, and MHI+. Here’s what to keep in mind:
              </div>
            </div>

            <div className="desktop-view-1 mt-3" style={{ gap: "5%" }}>
              {section5?.map((item, indx) => {
                return (
                  <ZoomInDiv
                    className="mini-card-section-4-htw d-flex flex-column width-for-text-heading-30"
                    style={{
                      padding: "30px",
                      // width: "30%",
                      height : "450px",
                      alignItems: "center",
                      gap: "30px",
                      background:
                        indx+1 == selectSec ? "rgba(0, 160, 227, 1)" : "#FFFFFF",
                    }}
                    onClick = {() => {
                      setSelectSec(indx+1)
                    }}
                  >
                    <div
                      className="mini-card-num-div-section-4-htw"
                      style={{ padding: "10px", width: "20%" }}
                    >
                      <div className="mini-card-num-text-section-4-htw flex-center-row">
                        {indx + 1}
                      </div>
                    </div>
                    <div
                      className="mini-card-text-1-section-4-htw"
                      style={{
                        color: indx + 1 == selectSec ? "#FFFFFF" : "black",
                      }}
                    >
                      {item?.desc}
                    </div>
                    <div
                      className="mini-card-text-2-section-4-htw"
                      style={{
                        color: indx+1 == selectSec ? "#FFFFFF" : "rgba(97, 97, 97, 1)",
                      }}
                    >
                      {item?.text}
                    </div>
                  </ZoomInDiv>
                );
              })}
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <div
                className="sub-text-2-section-5-htw mt-5"
                style={{ width: "80%" }}
              >
                If you need help finding the best hair transplant clinics near you, I can point you in the right direction. Explore top-rated clinics
              </div>
            </div>

            <RightAnimatedDiv className="flex-center-row">
              <button
                onClick={() => navigate("/hair-test")}
                className="btn sub-button-section-3-op"
              >
                Take a Hair Test
              </button>
            </RightAnimatedDiv>
          </div>
        </div>

        <div className="main-div-resp">
          <div
            className="d-flex flex-column container"
            style={{ padding: "3% 0 3% 0" }}
          >
            <div className="text-1-section-4-htw">Recovery and Aftercare </div>
            <LeftAnimatedDiv
              style={{ display: "flex", justifyContent: "center" }}
            >
              <div className="text-2-section-4-htw" >
                What to <span className="blue-btw-text">Expect After Your Hair</span> Transplant
              </div>
            </LeftAnimatedDiv>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div className="text-3-section-4-htw" style={{ width: "75%" }}>
              Recovery is simpler than most people imagine! Here’s what I typically advise my patients:

              </div>
            </div>

            <div className="d-flex flex-column mt-3" style={{ gap: "15px" }}>
              {section4.map((e) => {
                return (
                  <ZoomInDiv className="sub-section-4-htw d-flex">
                    <div className="sub-img-section-4-htw">
                      <img
                        src={e?.img}
                        style={{ height: "100%", width: "100%" }}
                      />
                    </div>
                    <div
                      style={{
                        width: "65%",
                        padding: "20px 0 10px 0",
                        gap: "10px",
                      }}
                      className="d-flex flex-column"
                    >
                      <div className="sub-text-1-section-4-htw">{e?.desc}</div>
                      <div className="sub-text-2-section-4-htw">{e?.text}</div>
                    </div>
                  </ZoomInDiv>
                );
              })}
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <div
                className="sub-text-2-section-3-htw mt-5"
                style={{ width: "80%", color: "black" }}
              >
                Each woman’s hair loss journey is unique, so finding the right
                combination of treatments is key. And remember, it’s about
                long-term care, not just a quick fix!
              </div>
            </div>
          </div>
        </div>

        <div style={{ background: "rgba(193, 237, 255, 1)" }} className="main-div-resp">
          <div className="main-section-1-htw desktop-view-1 container">
            <div className="d-flex flex-column main-section-1-htw-left">
              <ZoomInDiv className="text-1-section-1-htw-left">
              Conclusion
              </ZoomInDiv>
              <div className="text-3-section-1-htw-left">
              Modern hair transplants are a game-changer. Whether you’re considering FUE, DHI, MHI, or MHI+, each method offers long-lasting, natural results tailored to your specific needs. With competitive pricing and top clinics available, there’s no need to let hair loss hold you back. Take that first step toward a fuller, healthier head of hair by booking your free consultation today.

                <br />
                <br />
                Schedule Your Free Consultation Now
              </div>
              <ZoomInDiv className="">
              <HashLink
                  smooth
                  to="/book/#section3"
                  style={{ textDecoration: "none", color: "black" }}
                >
                <button
                  // onClick={() => navigate("/hair-test")}
                  className="btn sub-button-section-3-op"
                >
                  Schedule Now
                </button>
                </HashLink>
              </ZoomInDiv>
            </div>
            <RightAnimatedDiv className="d-flex flex-column main-section-1-htw-right">
              <img src="https://res.cloudinary.com/drkpwvnun/image/upload/v1729540911/hair-assessment/tfr0a7qn9yq5knjkrmxq.png" />
            </RightAnimatedDiv>
          </div>
        </div>

        <div className="container mt-3 main-div-resp">
          <ZoomInDiv2 className="main-text-section-8-htw">
            Frequently Asked Questions (FAQs)
          </ZoomInDiv2>
          <div className="mt-5 d-flex flex-column">
            {section8?.map((item, indx) => {
              return (
                <div className="d-flex flex-column">
                  <div
                    className="main-heading-box-section-8-htw d-flex"
                    style={{ justifyContent: "space-between" }}
                  >
                    <div
                      className="main-heading-text-section-8-htw faq-text"
                      style={{ padding: "10px" }}
                    >
                      {item?.desc}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      onClick={() => {
                        if(selectedQ == indx + 1) setSelectedQ(0);
                        else setSelectedQ(indx + 1);
                      }}
                      className=" faq-icon"
                    >
                      <img
                        src={"/assets/img/hairTreatmentWomen/image-16.png"}
                        className="faq-dropDown"
                      />
                    </div>
                  </div>
                  {selectedQ == indx + 1 ? (
                    <div className="main-sub-heading-text-section-8-htw">
                      {item?.text}
                    </div>
                  ) : null}
                </div>
              );
            })}

<div className="text-3-section-1-htw-left" style={{padding : "30px",textAlign : "center"}}>
Don’t let hair loss control your life. Whether you’re considering FUE, DHI, or MHI, let’s work together to find the best solution for you. Start your journey by scheduling your consultation today!

              </div>
          </div>
        </div>

        <ShoppingFeature col={"1"} />
        <Footer />
      </div>
    </Navbar>
  );
}
