import React, { useState } from "react";
import "./index.css";
import BeforeAfter from "../before-after/BeforeAfter";
import Footer from "../footer/Footer";
import Faq from "../our-specialist/faq/Faq";
import { useSelector } from "react-redux";
import {
  LeftAnimatedDiv,
  RightAnimatedDiv,
  ZoomInDiv,
  ZoomInDiv2
} from "../../componet/Animation";
import ShoppingFeature from "../shopping-feature/ShoppingFeature";
import Navbar from "../nav/Navbar";
import { Padding } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
export default function HairTreatmentWomen() {
  const [read1, setRead1] = useState(false);
  const [read2, setRead2] = useState(false);
  const [read3, setRead3] = useState(false);
  const [read4, setRead4] = useState(false);
  const [read5, setRead5] = useState(false);
  const [read6, setRead6] = useState(false);
  const [read7, setRead7] = useState(false);
  const [read8, setRead8] = useState(false);
  const [read9, setRead9] = useState(false);
  const [read10, setRead10] = useState(false);

  const content = useSelector((state) => state.content.expertise);
  console.log("jojkrgreor", content);
  const navigate = useNavigate();

  let section3 = [
    {
      img: "/assets/img/hairTreatmentWomen/image-4.png",
      desc: "Hormonal Imbalances",
      text: "Things like menopause, pregnancy, or thyroid issues can wreak havoc on your hair.",
    },
    {
      img: "/assets/img/hairTreatmentWomen/image-5.png",
      desc: "Genetics",
      text: "If your mom or grandmother had androgenetic alopecia (hereditary hair loss), there’s a chance you might, too.",
    },
    {
      img: "https://res.cloudinary.com/drkpwvnun/image/upload/v1730232446/hair-assessment/qgxyqoi5f0vjiue9fji5.png",
      desc: "Stress",
      text: "Both physical and emotional stress can cause telogen effluvium, a temporary shedding that could lead to more severe thinning if not addressed.",
    },
    {
      img: "/assets/img/hairTreatmentWomen/image-7.png",
      desc: "Dietary Deficiencies",
      text: "A lack of iron or biotin can make your hair weak and thin. We often overlook how important a nutrient-rich diet is for hair health.",
    },
    {
      img: "/assets/img/hairTreatmentWomen/image-8.png",
      desc: "Medical Conditions",
      text: "Conditions like autoimmune diseases or scalp infections can interfere with your hair’s growth cycle",
    },
  ];

  let section4 = [
    {
      img: "/assets/img/hairTreatmentWomen/image-9.png",
      desc: "Medical Treatments",
      text: "Dermatologist-approved medications like Minoxidil or Redensyl can stimulate regrowth and stop further loss",
    },
    {
      img: "https://res.cloudinary.com/drkpwvnun/image/upload/v1730232542/hair-assessment/fitc8qfmqebq7mljrtvi.png",
      desc: "Hair Procedures",
      text: "Options like PRP (Platelet-Rich Plasma), VGROW, Stem Cell PRP, GFC PRP, and VThread Therapy are fantastic for rejuvenating the scalp and promoting new hair growth. I’ve seen incredible results with these in my practice.",
    },
    {
      img: "/assets/img/hairTreatmentWomen/image-11.png",
      desc: "Hair Transplants",
      text: "For those dealing with more advanced hair loss, procedures like FUE (Follicular Unit Extraction), DHI (Direct Hair Implantation), and my own innovations, MHI / MHI+ (Modified Hair Implantation), can work wonders. These are especially effective for women with significant thinning.",
    },
    {
      img: "/assets/img/hairTreatmentWomen/image-12.png",
      desc: "Natural Remedies",
      text: "If you’re looking for a more holistic approach, essential oils like rosemary and supplements like biotin or zinc can strengthen your hair naturally. I always recommend pairing these with medical treatments for the best results.",
    },
    {
      img: "/assets/img/hairTreatmentWomen/image-13.png",
      desc: "Lifestyle Adjustments",
      text: "Reducing stress and sticking to a nutrient-rich diet can do wonders for your long-term hair health",
    },
  ];

  const [section5Sel,setSection5Sel] = useState(1)
  let section5 = [
    {
      desc: "Minoxidil",
      text: "This topical treatment is a tried-and-true solution for stimulating hair growth",
    },
    {
      desc: "Hair-Strengthening Shampoos",
      text: "Products formulated with DHT blockers or ketoconazole can help reduce shedding and encourage regrowth.",
    },
    {
      desc: "Essential Oils",
      text: "Oils like rosemary and peppermint improve scalp circulation, which promotes growth.",
    },
    {
      desc: "Hair Supplements",
      text: "Adding biotin, iron, or zinc to your diet can strengthen your hair and improve scalp health.",
    },
  ];
  const [selectedQ, setSelectedQ] = useState(0);

  let section8 = [
    {
      desc: "What causes hair loss in women? ",
      text: "Hair loss in women can stem from hormonal changes (like menopause or pregnancy), genetics, stress, nutritional deficiencies, or underlying medical conditions. Each factor requires a unique treatment plan.",
    },
    {
      desc: "How does a hair test help with hair loss? ",
      text: "A hair test from HairsnCares.com analyzes your hair health, pinpointing the exact reasons for your hair loss. This helps us design a treatment plan specifically customized to suit your unique needs",
    },
    {
      desc: "What are the best female hair loss treatments? ",
      text: "The best treatments range from Minoxidil and Redensyl to advanced procedures like PRP and Stem Cell Therapy. Personalized plans will help find the best option for your specific needs.",
    },
    {
      desc: "Is thinning hair in women reversible? ",
      text: "Yes, in many cases, thinning hair can be reversed if caught early. The success of treatments depends on identifying the root cause and acting quickly.",
    },
    {
      desc: "What are the most effective hair loss remedies for women? ",
      text: "Effective remedies include medical treatments like VGROW, Stem Cell PRP, and in some cases, hair transplants like MHI / MHI+.",
    },
  ];

  return (
    <Navbar>
      <div
        className="d-flex flex-column"
        style={{ fontFamily: '"Poppins", sans-serif' }}
      >
        <div style={{ background: "rgba(193, 237, 255, 1)" }} className="main-div-resp">
          <div className="main-section-1-htw desktop-view-1 container">
            <ZoomInDiv               
              variants={{ hidden: { opacity: 0 }, visible: { opacity: 1 } }}
              transition={{ duration: 1, delay: 0.5 }} 
              className="d-flex flex-column main-section-1-htw-left">
              <div className="text-1-section-1-htw-left">
                Hair Loss in Women
              </div>
              <div className="text-2-section-1-htw-left">
                How to  <span className="blue-btw-text">Stop It</span> Before It Starts
              </div>
              <div className="text-3-section-1-htw-left">
                Hi, I’m Dr. Amit Agarkar, a dermatologist and trichologist with
                over 20 years of experience in helping people regain their hair
                and confidence. Let’s dive into a topic that affects millions of
                women—hair loss. It’s a common issue, but it doesn’t have to be
                your forever reality. Today, I’m going to break down why women
                experience hair loss, what you can do about it, and the
                treatments that work best based on my own professional
                experience
              </div>
            </ZoomInDiv>
            <RightAnimatedDiv className="d-flex flex-column main-section-1-htw-right">
              <img src="/assets/img/hairTreatmentWomen/image.png" />
            </RightAnimatedDiv>
          </div>
        </div>

        <div className="d-flex flex-column main-section-2-htw container main-div-resp">
          <div className="text-1-section-2-htw">The Problem</div>
          <ZoomInDiv2 className="text-2-section-2-htw">
            Hair Loss in <span className="blue-btw-text">Women</span> Can Impact Your Confidence and <span className="blue-btw-text">Well-Being</span>
          </ZoomInDiv2>
          <div className="desktop-view">
            <LeftAnimatedDiv className="main-section-2-htw-left">
              <img
                src="/assets/img/hairTreatmentWomen/image-2.png"
                style={{ height: "90%", width: "98%" }}
              />
            </LeftAnimatedDiv>
            <RightAnimatedDiv className="text-3-section-2-htw main-section-2-htw-right">
              I’ve seen firsthand how hair loss in women can take a toll on not
              just their appearance, but their mental well-being. It’s more than
              just a cosmetic issue—it impacts your self-esteem, causes stress,
              and can even make you shy away from social situations. I had a
              patient, Anjali, who came to me because she was experiencing
              thinning hair in her early 30s. She was anxious about the shedding
              she was seeing, and it started to affect her confidence at work.
              But here’s the good news: once we figured out the cause, we could
              treat it effectively, and she’s feeling so much better about
              herself now! If you’ve noticed thinning or increased shedding,
              you’re not alone. Women of all ages experience this, and the
              earlier you take action, the better your chances of preventing
              severe hair loss.
            </RightAnimatedDiv>
          </div>
        </div>

        <div className="main-section-3-htw main-div-resp">
          <div className="d-flex flex-column container" style={{padding : "3% 0 3% 0"}}>
            <div className="text-1-section-3-htw">The Problem</div>
            <ZoomInDiv2 style={{ display: "flex", justifyContent: "center" }}>
              <div className="text-2-section-3-htw width-for-text-heading-80" >
                Understanding the <span className="blue-btw-text">Causes</span> of Hair Loss in Women
              </div>
            </ZoomInDiv2>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div className="text-3-section-3-htw width-for-text-heading-75">
                Let’s talk about the culprits behind hair loss in women. It’s
                crucial to figure out the reason behind your thinning hair
                because the treatment will depend on the root cause. Some of the
                main triggers I see in my practice are:
              </div>
            </div>

            <div className="d-flex flex-column mt-3" style={{ gap: "15px" }}>
              {section3.map((e) => {
                return (
                  <ZoomInDiv className="sub-section-3-htw d-flex">
                    <div className="sub-img-section-3-htw">
                      <img
                        src={e?.img}
                        style={{ height: "100%", width: "100%" }}
                      />
                    </div>
                    <div
                      style={{
                        width: "65%",
                        // padding: "20px 0 10px 0",
                        gap: "10px",
                      }}
                      className="d-flex flex-column horizontal-card-text"
                    >
                      <div className="sub-text-1-section-3-htw">{e?.desc}</div>
                      <div className="sub-text-2-section-3-htw">{e?.text}</div>
                    </div>
                  </ZoomInDiv>
                );
              })}
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <div
                className="sub-text-2-section-3-htw mt-5 width-for-text-heading-80"
                // style={{ width: "80%" }}
              >
                Here’s a pro tip: If you’re unsure of what’s causing your hair
                loss, taking a hair test from HairsnCares.com can help identify
                the underlying issue and lead you to the best treatments. Trust
                me, it’s worth the insight!
              </div>
            </div>

            <RightAnimatedDiv className="flex-center-row">
              <button
                onClick={() => navigate("/hair-test")}
                className="btn sub-button-section-3-op"
                >
                TAKE AN ONLINE HAIR TEST
              </button>
            </RightAnimatedDiv>
          </div>
        </div>

        <div className="main-div-resp">
          <div className="d-flex flex-column container" style={{padding : "3% 0 3% 0"}}>
            <div className="text-1-section-4-htw">The Solution</div>
            <LeftAnimatedDiv style={{ display: "flex", justifyContent: "center" }}>
              <div className="text-2-section-4-htw width-for-text-heading-80" >
                Find the <span className="blue-btw-text">Right Female Hair Loss</span> Treatment for You
              </div>
            </LeftAnimatedDiv>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div className="text-3-section-4-htw width-for-text-heading-75">
                Once we figure out what’s causing the hair loss, it’s time to
                get to the fun part—treatment! There are several options
                available depending on the severity and type of hair loss you’re
                dealing with. I’ve helped countless women regain their hair with
                a combination of the following:
              </div>
            </div>

            <div className="d-flex flex-column mt-3" style={{ gap: "15px" }}>
              {section4.map((e) => {
                return (
                  <ZoomInDiv className="sub-section-4-htw d-flex">
                    <div className="sub-img-section-4-htw">
                      <img
                        src={e?.img}
                        style={{ height: "100%", width: "100%" }}
                      />
                    </div>
                    <div
                      style={{
                        width: "65%",
                        // padding: "20px 0 10px 0",
                        gap: "10px",
                      }}
                      className="d-flex flex-column horizontal-card-text"
                    >
                      <div className="sub-text-1-section-4-htw">{e?.desc}</div>
                      <div className="sub-text-2-section-4-htw">{e?.text}</div>
                    </div>
                  </ZoomInDiv>
                );
              })}
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <div
                className="sub-text-2-section-3-htw mt-5"
                style={{ width: "80%", color: "black" }}
              >
                Each woman’s hair loss journey is unique, so finding the right
                combination of treatments is key. And remember, it’s about
                long-term care, not just a quick fix!
              </div>
            </div>
          </div>
        </div>

        <div className="main-section-5-htw main-div-resp">
          <div className="d-flex flex-column container" style={{padding : "3% 0 3% 0"}}>
            <div className="text-1-section-5-htw mt-3">
              Hair Loss Remedies for Women{" "}
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <RightAnimatedDiv className="text-2-section-5-htw width-for-text-heading-80">
                Regain <span className="blue-btw-text">Control Over</span> Your Hair Health
              </RightAnimatedDiv>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div className="text-3-section-5-htw width-for-text-heading-75">
                Many women can stop their hair loss before it gets severe by
                turning to early remedies. Some of the most effective ones I
                recommend are:
              </div>
            </div>

            <div className="desktop-view-1 mt-3" style={{ gap: "15px" }}>
              {section5?.map((item, indx) => {
                return (
                  <ZoomInDiv
                    className="mini-card-section-4-htw d-flex flex-column width-for-text-heading-23"
                    style={{
                      padding: "30px",
                      // width: "23%",
                      alignItems: "center",
                      gap: "15px",
                      cursor: "pointer",
                      background:
                        indx == section5Sel ? "rgba(0, 160, 227, 1)" : "#FFFFFF",
                    }}
                    onClick = {() => {
                      setSection5Sel(indx)
                    }}
                  >
                    <div
                      className="mini-card-num-div-section-4-htw"
                      style={{ padding: "10px", width: "20%" }}
                    >
                      <div className="mini-card-num-text-section-4-htw flex-center-row">
                        {indx + 1}
                      </div>
                    </div>
                    <div
                      className="mini-card-text-1-section-4-htw"
                      style={{
                        color: section5Sel == indx ? "#FFFFFF" : "black",
                      }}
                    >
                      {item?.desc}
                    </div>
                    <div
                      className="mini-card-text-2-section-4-htw"
                      style={{
                        color: section5Sel == indx ? "#FFFFFF" : "rgba(97, 97, 97, 1)",
                      }}
                    >
                      {item?.text}
                    </div>
                  </ZoomInDiv>
                );
              })}
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <div
                className="sub-text-2-section-5-htw mt-5"
                style={{ width: "80%" }}
              >
                If you’re not sure where to start, taking a hair test from
                HairsnCares.com can point you in the right direction for your
                hair’s unique needs.
              </div>
            </div>

            <RightAnimatedDiv className="flex-center-row">
              <button
                onClick={() => navigate("/shop")}
                className="btn sub-button-section-3-op"
                >
                Explore Remedies
              </button>
            </RightAnimatedDiv>
          </div>
        </div>

        <div className="d-flex flex-column main-section-2-htw container main-div-resp">
          <div className="desktop-view">
            <LeftAnimatedDiv className="main-section-2-htw-left">
              <img
                src="/assets/img/hairTreatmentWomen/image-14.png"
                style={{ height: "100%", width: "100%" }}
              />
            </LeftAnimatedDiv>
            <div className=" main-section-2-htw-right d-flex flex-column">
              <div
                className="text-1-section-2-htw"
                style={{ textAlign: "left" }}
              >
                Act Now{" "}
              </div>
              <ZoomInDiv2
                className="text-2-section-2-htw"
                style={{ textAlign: "left" }}
              >
                Take a <span className="blue-btw-text">Hair Test</span> to Prevent Thinning Hair
              </ZoomInDiv2>
              <div className="text-3-section-2-htw">
                Here’s what I always tell my patients: the sooner you address
                your hair loss, the better your chances of reversing it. Don’t
                wait until your hair loss becomes obvious—early intervention
                gives you more options, whether you prefer natural remedies or
                more intensive medical treatments.
                <br />
                <br />
                Take a hair test from HairsnCares.com today to discover what’s
                causing your hair loss and start a treatment plan that works for
                you. Let’s work together to get your hair back on track!
              </div>
              <div className="">
              <button
                onClick={() => navigate("/hair-test")}
                className="btn sub-button-section-3-op"
                >
               Take Hair Test
              </button>
            </div>
            </div>
          </div>
        </div>

        <div style={{ background: "rgba(193, 237, 255, 1)" }} className="main-div-resp">
          <div className="main-section-1-htw desktop-view-1 container">
            <div className="d-flex flex-column main-section-1-htw-left">
              <ZoomInDiv className="text-1-section-1-htw-left">Conclusion</ZoomInDiv>
              <div className="text-3-section-1-htw-left">
                Hair loss in women can feel overwhelming, but with the right
                treatment, you can regain control over your hair health. From
                medical treatments like Minoxidil to natural solutions like
                essential oils and supplements, there’s a solution for everyone.
                The key is to act early and understand the underlying cause of
                your hair loss. With personalized treatment options available,
                you can restore your hair—and your confidence—sooner than you
                think.
                <br />
                <br />
                Ready to get started? Explore Your Treatment Options Now and
                start your journey toward fuller, healthier hair!
              </div>
              <ZoomInDiv className="">
              <button
                onClick={() => navigate("/hair-test")}
                className="btn sub-button-section-3-op"
                >
               Start Your Treatment
              </button>
            </ZoomInDiv>
            </div>
            <RightAnimatedDiv className="d-flex flex-column main-section-1-htw-right">
              <img src="/assets/img/hairTreatmentWomen/image-15.png" />
            </RightAnimatedDiv>
          </div>
        </div>

        <div className="container mt-3 main-div-resp">
          <ZoomInDiv2 className="main-text-section-8-htw">
            Frequently Asked Questions (FAQs)
          </ZoomInDiv2>
          <div className="mt-5 d-flex flex-column">
            {section8?.map((item, indx) => {
              return (
                <div className="d-flex flex-column">
                                  <div
                  className="main-heading-box-section-8-htw d-flex"
                  style={{ justifyContent: "space-between" }}
                >
                  <div
                    className="main-heading-text-section-8-htw faq-text"
                    style={{ padding: "10px" }}
                  >
                   {item?.desc}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    onClick={() => {
                      if(selectedQ == indx + 1) setSelectedQ(0);
                      else setSelectedQ(indx + 1);
                    }}
                    className="faq-icon"
                  >
                    <img
                      src={"/assets/img/hairTreatmentWomen/image-16.png"}
                      className='faq-dropDown'

                    />
                  </div>
                </div>
                {selectedQ == indx + 1 ? (
                    <div className="main-sub-heading-text-section-8-htw">
                                         {item?.text}

                    </div>
                  ) : null}
                </div>
              );
            })}
          </div>
        </div>

        <ShoppingFeature col={"1"} />
        <Footer />
      </div>
    </Navbar>
  );
}
