import React, { useState } from "react";
import "./index.css";
import BeforeAfter from "../before-after/BeforeAfter";
import Footer from "../footer/Footer";
import Faq from "../our-specialist/faq/Faq";
import { useSelector } from "react-redux";
import {
  LeftAnimatedDiv,
  RightAnimatedDiv,
  ZoomInDiv,
} from "../../componet/Animation";
import ShoppingFeature from "../shopping-feature/ShoppingFeature";
import Navbar from "../nav/Navbar";
import { Padding } from "@mui/icons-material";
export default function HairTreatmentMen() {
  const [read1, setRead1] = useState(false);
  const [read2, setRead2] = useState(false);
  const [read3, setRead3] = useState(false);
  const [read4, setRead4] = useState(false);
  const [read5, setRead5] = useState(false);
  const [read6, setRead6] = useState(false);
  const [read7, setRead7] = useState(false);
  const [read8, setRead8] = useState(false);
  const [read9, setRead9] = useState(false);
  const [read10, setRead10] = useState(false);

  const content = useSelector((state) => state.content.expertise);
  console.log("jojkrgreor", content);

  let section3 = [
    {
      img: "https://res.cloudinary.com/drkpwvnun/image/upload/v1729376530/hair-assessment/gyuljzehtovguzt0til7.png",
      desc: "Nutritional Support",
      text: "A balanced diet rich in protein, iron, zinc, biotin, and omega-3 fatty acids is essential for hair health. Deficiencies in these nutrients can lead to hair thinning or loss.",
    },
    {
      img: "https://res.cloudinary.com/drkpwvnun/image/upload/v1729376572/hair-assessment/a8udim7s95eahadmv1en.png",
      desc: "Lifestyle Changes",
      text: "Managing stress through practices like meditation, exercise, and adequate sleep, along with regular scalp care, is crucial for hair growth. Scalp massages and improved circulation through physical activity can also promote healthy hair.",
    },
    {
      img: "https://res.cloudinary.com/drkpwvnun/image/upload/v1729376593/hair-assessment/ue0b1mc4fgcw82uu56um.png",
      desc: "Avoid Damaging Hair Treatments",
      text: " Limit the use of excessive heat, chemical treatments, and tight hairstyles, which can damage hair follicles.",
    },
    {
      img: "https://res.cloudinary.com/drkpwvnun/image/upload/v1729376641/hair-assessment/rewcimkla0j8lwy7vqri.png",
      desc: "Dietary Deficiencies",
      text: "A lack of iron or biotin can make your hair weak and thin. We often overlook how important a nutrient-rich diet is for hair health.",
    }
  ];


  let section5 = [
    {
      desc: "Receding Hairline",
      text: "A receding hairline is often the first noticeable sign of male pattern baldness. This type of hair loss typically begins at the temples and moves backward, creating an M-shaped pattern. Men may experience this in their late teens or early twenties",
    },
    {
      desc: "Thinning at the Crown",
      text: "Another common pattern of hair loss is thinning at the crown of the head. This often begins as a small bald spot that gradually expands outward. Men who experience this type of hair loss are likely in the middle stages of male pattern baldness (Norwood stages 3 to 5).",
    },
    {
      desc: "Generalized Thinning",
      text: "Unlike specific areas like the hairline or crown, some men experience generalized thinning across the entire scalp. This can lead to an overall reduction in hair density, making hair look noticeably thinner.",
    },
    {
      desc: "Complete Balding",
      text: "In the most advanced stages of male pattern baldness, men may experience complete balding, where only a thin ring of hair remains around the sides and back of the scalp. While this pattern is often genetic and largely irreversible, there are options to address it.",
    },
  ];
  const [selectedQ, setSelectedQ] = useState(0);

  let section8 = [
    {
      desc: "Can Hair Loss Be Reversed? ",
      text: "While some types of hair loss, such as telogen effluvium, can be reversed once the underlying cause is addressed, genetic hair loss (male pattern baldness) is typically permanent. However, treatments like minoxidil, finasteride, and hair transplants can slow down or reverse the progression in many cases.",
    },
    {
      desc: "How Do I Know If I’m Losing My Hair?",
      text: "Signs of hair loss include a receding hairline, thinning at the crown, or noticeable shedding during combing or washing. If you suspect hair loss, consult with a healthcare provider or dermatologist for a proper diagnosis.",
    },
    {
      desc: "Are There Natural Remedies for Hair Loss?",
      text: "Some natural remedies, such as massaging the scalp with essential oils (e.g., rosemary oil), consuming a nutrient-rich diet, and using supplements like biotin, can promote hair health. However, their effectiveness may vary from person to person, and they are not substitutes for clinically proven treatments.",
    },
  ];

  return (
    <Navbar>
      <div
        className="d-flex flex-column"
        style={{ fontFamily: '"Poppins", sans-serif' }}
      >
        <div style={{ background: "rgba(193, 237, 255, 1)" }} className="main-div-resp">
          <div className="main-section-1-htm desktop-view-1 container">
            <ZoomInDiv
              className="d-flex flex-column main-section-1-htw-left"
              style={{ padding: 0 }}
            >
              <div className="text-2-section-1-htw-left">
                Hair Loss for Men: Causes, <span className="blue-btw-text">Treatments</span>, and Prevention Tips
              </div>
              <div className="text-3-section-1-htw-left">
                Hair loss, or alopecia, is a common condition that affects
                millions of men worldwide. While it's often considered a natural
                part of aging, hair loss can cause emotional distress and impact
                self-esteem. Whether you’re noticing a receding hairline,
                thinning hair, or bald patches, it’s important to understand the
                underlying causes and the range of treatment options available
                to manage and prevent further hair loss.
              </div>
              <div className="">
                <button
                  // onClick={() => navigate("/our-expertise#read3")}
                  className="btn sub-button-section-3-op"
                >
                  Learn More
                </button>
              </div>
            </ZoomInDiv>
            <RightAnimatedDiv className="d-flex flex-column main-section-1-op-right">
              <img src="https://res.cloudinary.com/drkpwvnun/image/upload/v1729374072/hair-assessment/lxxobxremnrinsomujb0.png" />
            </RightAnimatedDiv>
          </div>
        </div>

        <div className="d-flex flex-column main-section-2-htw container main-div-resp">
          <div className="desktop-view" style={{ gap: "30px" }}>
            <LeftAnimatedDiv
              className="main-section-2-htw-left"
              style={{ height: "100vh" }}
            >
              <img
                src="https://res.cloudinary.com/drkpwvnun/image/upload/v1729374530/hair-assessment/cwdxrkmyhfl0xa5gwmve.png"
                style={{ height: "100%", width: "100%" }}
              />
            </LeftAnimatedDiv>
            <div className=" main-section-2-htw-right d-flex flex-column">
              <RightAnimatedDiv
                className="text-2-section-2-htw"
                style={{ textAlign: "left" }}
              >
                What Causes <span className="blue-btw-text">Hair Loss</span> in Men?
              </RightAnimatedDiv>
              <div className="d-flex" style={{ gap: "10px" }}>
                <div
                  className="mini-card-num-div-section-4-htw number-view-1"
                  
                >
                  <div className="mini-card-num-text-section-4-htw flex-center-row">
                    1
                  </div>
                </div>
                <div className="text-3-section-2-htw">
                  Genetics (Male Pattern Baldness) One common cause of hair loss
                  in men is androgenetic alopecia, or male pattern baldness
                  (MPB). This hereditary condition typically starts with a
                  receding hairline or thinning at the crown and is caused by
                  sensitivity to dihydrotestosterone (DHT), which shrinks hair
                  follicles over time, leading to hair loss.
                </div>
              </div>

              <div className="d-flex" style={{ gap: "10px" }}>
                <div
                  className="mini-card-num-div-section-4-htw number-view-1"

                >
                  <div className="mini-card-num-text-section-4-htw flex-center-row">
                    2
                  </div>
                </div>
                <div className="text-3-section-2-htw">
                  Hormonal Changes Fluctuations in hormones can contribute to
                  hair loss. DHT plays a significant role, but other factors,
                  such as stress or medical conditions like thyroid disease, can
                  also disrupt the hormonal balance, triggering hair shedding.{" "}
                </div>
              </div>

              <div className="d-flex" style={{ gap: "10px" }}>
                <div
                  className="mini-card-num-div-section-4-htw number-view-1"

                >
                  <div className="mini-card-num-text-section-4-htw flex-center-row">
                    3
                  </div>
                </div>
                <div className="text-3-section-2-htw">
                  Medical Conditions and Medications Certain medical conditions,
                  including alopecia areata, autoimmune disorders, and scalp
                  infections, can cause hair loss. Additionally, medications for
                  conditions such as high blood pressure, depression, and cancer
                  treatments (like chemotherapy) are known to cause hair
                  thinning or baldness.{" "}
                </div>
              </div>
            </div>
          </div>

          <div className="desktop-view-1" style={{ gap: "30px" }}>
            <div className=" main-section-2-htw-right d-flex flex-column">
              <div className="d-flex" style={{ gap: "10px" }}>
                <div
                  className="mini-card-num-div-section-4-htw number-view-1"

                >
                  <div className="mini-card-num-text-section-4-htw flex-center-row">
                    4
                  </div>
                </div>
                <div className="text-3-section-2-htw">
                  Stress and Lifestyle Factors One common cause of hair loss in
                  men is androgenetic alopecia, or male pattern baldness (MPB).
                  This hereditary condition typically starts with a receding
                  hairline or thinning at the crown and is caused by sensitivity
                  to dihydrotestosterone (DHT), which shrinks hair follicles
                  over time, leading to hair loss.{" "}
                </div>
              </div>

              <div className="d-flex" style={{ gap: "10px" }}>
                <div
                  className="mini-card-num-div-section-4-htw number-view-1"

                >
                  <div className="mini-card-num-text-section-4-htw flex-center-row">
                    5
                  </div>
                </div>
                <div className="text-3-section-2-htw">
                  Nutritional Deficiencies A lack of essential vitamins and
                  minerals like iron, zinc, biotin, and vitamin D can contribute
                  to hair loss. Ensuring a balanced diet rich in nutrients can
                  support healthier hair growth.{" "}
                </div>
              </div>
            </div>
            <RightAnimatedDiv
              className="main-section-2-htw-left"
            >
              <img
                src="https://res.cloudinary.com/drkpwvnun/image/upload/v1729375381/hair-assessment/ysdywgg0ccth8ejoudj1.png"
                style={{ height: "100%", width: "100%" }}
              />
            </RightAnimatedDiv>
          </div>
        </div>



        <div className="main-section-5-htm padding-60">
          <div className="d-flex flex-column container">
            <RightAnimatedDiv style={{ display: "flex", justifyContent: "center" }}>
              <div className="text-2-section-5-htw" style={{ width: "80%",color : "white" }}>
              Common <span className="blue-btw-text">Hair Loss Patterns</span> in Men
              </div>
            </RightAnimatedDiv>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div className="text-3-section-5-htw width-for-text-heading-75" style={{color : "white" }}>
              Hair loss is a common issue for men, and it often follows recognizable patterns. For many men, losing hair is an expected part of aging, but the patterns and speed at which it occurs can vary significantly. Understanding these hair loss patterns is essential to determining the best treatment strategy to manage or prevent further hair thinning.
              </div>
            </div>

            <div className="desktop-view-1 mt-3" style={{ gap: "15px" }}>
              {section5?.map((item, indx) => {
                return (
                  <ZoomInDiv
                    className="mini-card-section-4-htw d-flex flex-column width-for-text-heading-23"
                    style={{
                      padding: "30px",
                      // width: "23%",
                      alignItems: "center",
                      gap: "15px",
                      background:
                        "#FFFFFF",
                    }}
                  >
                    <div
                      className="mini-card-num-div-section-4-htw"
                      style={{ padding: "10px", width: "20%" }}
                    >
                      <div className="mini-card-num-text-section-4-htw flex-center-row">
                        {indx + 1}
                      </div>
                    </div>
                    <div
                      className="mini-card-text-1-section-4-htw"
                      style={{
                        color: "black",
                      }}
                    >
                      {item?.desc}
                    </div>
                    <div
                      className="mini-card-text-2-section-4-htw"
                      style={{
                        color: "rgba(97, 97, 97, 1)",
                      }}
                    >
                      {item?.text}
                    </div>
                  </ZoomInDiv>
                );
              })}
            </div>

          </div>
        </div>

        <div className="d-flex flex-column main-section-2-htw container main-div-resp"  >
        <ZoomInDiv className="text-2-section-2-htw">
        Top <span className="blue-btw-text">Hair Loss Treatments</span> & Preventive Care for Men
          </ZoomInDiv> 

          <div className="desktop-view" style={{ gap: "30px" }}>
            <LeftAnimatedDiv
              className="main-section-2-htw-left"
              // style={{ height: "100vh" }}
            >
              <img
                src="https://res.cloudinary.com/drkpwvnun/image/upload/v1729376226/hair-assessment/tzdshm6xhahgneqbjpm8.png"
                style={{ height: "100%", width: "100%" }}
              />
            </LeftAnimatedDiv>
            <div className=" main-section-2-htw-right d-flex flex-column">
              <div className="d-flex" style={{ gap: "10px" }}>
                <div
                  className="mini-card-num-div-section-4-htw number-view-1"

                >
                  <div className="mini-card-num-text-section-4-htw flex-center-row">
                    1
                  </div>
                </div>
                <div className="text-3-section-2-htw">
                  <strong>Minoxidil</strong><br/>
                  Minoxidil is a topical treatment available over-the-counter that has been proven to promote hair regrowth by stimulating blood flow to the scalp. It’s most effective in men who are experiencing early stages of hair thinning or mild hair loss. Regular application is necessary to maintain results.
                </div>
              </div>

              <div className="d-flex" style={{ gap: "10px" }}>
                <div
                  className="mini-card-num-div-section-4-htw number-view-1"

                >
                  <div className="mini-card-num-text-section-4-htw flex-center-row">
                    2
                  </div>
                </div>
                <div className="text-3-section-2-htw">
                  <strong>Minoxidil</strong><br/>
                  Finasteride is an oral prescription medication that blocks the conversion of testosterone to DHT, the hormone responsible for male pattern baldness. Studies show that finasteride can effectively stop hair loss and promote regrowth in many men                </div>
              </div>

              <div className="d-flex" style={{ gap: "10px" }}>
                <div
                  className="mini-card-num-div-section-4-htw number-view-1"

                >
                  <div className="mini-card-num-text-section-4-htw flex-center-row">
                    3
                  </div>
                </div>
                <div className="text-3-section-2-htw">
                  <strong>Laser Therapy</strong><br/>
                  Low-level laser therapy (LLLT) is a non-invasive treatment that uses red light to stimulate hair follicles and improve circulation in the scalp. Devices like laser caps or helmets are available for at-home use and have shown promise in improving hair density in some men.                </div>
              </div>
            </div>
          </div>

          <div className="desktop-view-1" style={{ gap: "30px" }}>
            <div className=" main-section-2-htw-right d-flex flex-column">
              <div className="d-flex" style={{ gap: "10px" }}>
                <div
                  className="mini-card-num-div-section-4-htw number-view-1"

                >
                  <div className="mini-card-num-text-section-4-htw flex-center-row">
                    4
                  </div>
                </div>
                <div className="text-3-section-2-htw">
                  <strong>Hair Transplant Surgery</strong><br/>
                  For men seeking a permanent solution, hair transplant surgery is a popular option. Techniques like Follicular Unit Transplantation (FUT) and Follicular Unit Extraction (FUE), DHI, Modified Hair Implantation (MHI), MHI+ involve moving healthy hair follicles from one part of the scalp to areas experiencing thinning or baldness. Hair transplants can offer natural-looking results.                </div>
              </div>

              <div className="d-flex" style={{ gap: "10px" }}>
                <div
                  className="mini-card-num-div-section-4-htw number-view-1"

                >
                  <div className="mini-card-num-text-section-4-htw flex-center-row">
                    5
                  </div>
                </div>
                <div className="text-3-section-2-htw">
                  <strong>Platelet-Rich Plasma (PRP) Therapy</strong><br/>
                  PRP therapy involves injecting platelet-rich plasma from your blood into the scalp to promote hair growth. This treatment has gained popularity in recent years due to its potential to naturally stimulate hair follicles. However, it may require multiple sessions for optimal results.                </div>
              </div>
            </div>
            <RightAnimatedDiv
              className="main-section-2-htw-left"
            >
              <img
                src="https://res.cloudinary.com/drkpwvnun/image/upload/v1729376324/hair-assessment/pxzgp1sopfdonj01g3gl.png"
                style={{ height: "100%", width: "100%" }}
              />
            </RightAnimatedDiv>
          </div>
        </div>

        <div style={{ background: "rgba(193, 237, 255, 1)",padding : "60px 0 60px 0" }}>
          <div className="d-flex flex-column container">
            <div style={{ display: "flex", justifyContent: "center" }}>
              <RightAnimatedDiv className="text-2-section-5-htw" style={{ width: "80%" }}>
              Preventive Care for <span className="blue-btw-text">Hair Loss in Men</span>: Role of Diet and Lifestyle
              </RightAnimatedDiv>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div className="text-3-section-5-htw width-for-text-heading-75" >
              While genetic factors like androgenetic alopecia cannot be changed, men can take steps to reduce the risk of hair loss and promote healthier hair growth:              </div>
            </div>

            <div className="desktop-view-1 mt-3" style={{ gap: "15px" }}>
              {section3?.map((item, indx) => {
                return (
                  <ZoomInDiv
                    className="mini-card-section-4-htw d-flex flex-column width-for-text-heading-23"
                    style={{
                      padding: "10px",
                      // width: "23%",
                      alignItems: "center",
                      gap: "15px",
                      background:
                        "none",
                    }}
                  >
                    <div
                      // className="mini-card-num-div-section-4-htw"
                      style={{     width: "100%" }}
                    >
                      <img src={item?.img}                        style={{     width: "100%" }}
                      />
                    </div>
                    <div
                      className="mini-card-text-1-section-4-htw"
                      style={{
                        color: "black",
                      }}
                    >
                      {item?.desc}
                    </div>
                    <div
                      className="mini-card-text-2-section-4-htw"
                      style={{
                        color: "rgba(97, 97, 97, 1)",
                      }}
                    >
                      {item?.text}
                    </div>
                  </ZoomInDiv>
                );
              })}
            </div>

          </div>
        </div>


        <div className="container mt-3 main-div-resp">
          <RightAnimatedDiv className="main-text-section-8-htw">
            Frequently <span className="blue-btw-text">Asked Questions</span> (FAQs)
          </RightAnimatedDiv>
          <div className="mt-5 d-flex flex-column">
            {section8?.map((item, indx) => {
              return (
                <div className="d-flex flex-column">
                  <div
                    className="main-heading-box-section-8-htw d-flex"
                    style={{ justifyContent: "space-between" }}
                  >
                    <div
                      className="main-heading-text-section-8-htw faq-text"
                      style={{ padding: "10px" }}
                    >
                      {item?.desc}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      onClick={() => {
                        if(selectedQ == indx + 1) setSelectedQ(0);
                        else setSelectedQ(indx + 1);
                      }}
                      className="faq-icon"
                    >
                      <img
                        src={"/assets/img/hairTreatmentWomen/image-16.png"}
                        className='faq-dropDown'

                      />
                    </div>
                  </div>
                  {selectedQ == indx + 1 ? (
                    <div className="main-sub-heading-text-section-8-htw">
                      {item?.text}
                    </div>
                  ) : null}
                </div>
              );
            })}
          </div>
        </div>

        <ShoppingFeature col={"1"} />
        <Footer />
      </div>
    </Navbar>
  );
}
